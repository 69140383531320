import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/rootReducer";
import { Provider } from "react-redux";

// Pages
// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Homeone = React.lazy(() => import("./components/pages/Homeone"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
const Homefive = React.lazy(() => import("./components/pages/Homefive"));
// Vendor
const Vendor = React.lazy(() => import("./components/pages/Vendor"));
const Vendorone = React.lazy(() => import("./components/pages/Vendorone"));
const Vendortwo = React.lazy(() => import("./components/pages/Vendortwo"));
const Addvendor = React.lazy(() => import("./components/pages/Addvendor"));
const Listview = React.lazy(() => import("./components/pages/Listview"));
// Innerpages
const Exdeals = React.lazy(() => import("./components/pages/Exdeals"));
const About = React.lazy(() => import("./components/pages/About"));
const AboutV2 = React.lazy(() => import("./components/pages/AboutV2"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
const Refund = React.lazy(() => import("./components/pages/Refund"));
const Shipping = React.lazy(() => import("./components/pages/Shipping"));
const Privacy = React.lazy(() => import("./components/pages/Privacy"));
const Reviews = React.lazy(() => import("./components/pages/Reviews"));
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Success = React.lazy(() => import("./components/pages/Success"));
const Cancel = React.lazy(() => import("./components/pages/Cancel"));
const Orderdetails = React.lazy(() =>
  import("./components/pages/Orderdetails")
);
// Listing
const Listingfull = React.lazy(() => import("./components/pages/Listingfull"));
const Listingleft = React.lazy(() => import("./components/pages/Listingleft"));
const Listingright = React.lazy(() =>
  import("./components/pages/Listingright")
);
const Listingdetails = React.lazy(() =>
  import("./components/pages/Listingdetails")
);
// Shop
const Shopfull = React.lazy(() => import("./components/pages/Shopfull"));
const Shopleft = React.lazy(() => import("./components/pages/Shopleft"));
const Shopright = React.lazy(() => import("./components/pages/Shopright"));
const Productdetails = React.lazy(() =>
  import("./components/pages/Productdetails")
);
const Vendormap = React.lazy(() => import("./components/pages/Vendormap"));
// Additional
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Geolocator = React.lazy(() => import("./components/pages/Geolocator"));
const Comparison = React.lazy(() => import("./components/pages/Comparison"));
// Blog
const Blog = React.lazy(() => import("./components/pages/Blog"));
const Blogtwo = React.lazy(() => import("./components/pages/Blogtwo"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
// Extra
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Error = React.lazy(() => import("./components/pages/Error"));

function App() {
  const store = createStore(rootReducer, applyMiddleware(thunk));
  // return(
  //   <h1 style={{textAlign:'center',paddingTop:30}}>500 Internal Server Error!</h1>
  // )
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            {/* Homepages */}
            <Route exact path="/" component={Home} />
            <Route exact path="/home-v1" component={Homeone} />
            <Route exact path="/home-v2" component={Hometwo} />
            <Route exact path="/home-v3" component={Homethree} />
            <Route exact path="/home-v4" component={Homefour} />
            <Route exact path="/home-v5" component={Homefive} />
            {/* Vendor */}
            <Route exact path="/vendor" component={Vendor} />
            <Route exact path="/vendor-v1" component={Vendorone} />
            <Route exact path="/vendor-v2" component={Vendortwo} />
            <Route exact path="/add-vendor" component={Addvendor} />
            <Route exact path="/list-view" component={Listview} />
            {/* Innerpages */}
            <Route exact path="/ex-deals" component={Exdeals} />
            <Route exact path="/about" component={AboutV2} />
            <Route exact path="/refund" component={Refund} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms-conditions" component={Terms} />
            <Route exact path="/shipping" component={Shipping} />
            <Route exact path="/reviews" component={Reviews} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/success/:id" component={Success} />
            <Route exact path="/cancel" component={Cancel} />
            <Route exact path="/order-details" component={Orderdetails} />
            {/* Listing */}
            <Route exact path="/listing-full" component={Listingfull} />
            <Route exact path="/listing-left" component={Listingleft} />
            <Route exact path="/listing-right" component={Listingright} />
            <Route
              exact
              path="/listing-detail/:id"
              component={Listingdetails}
            />
            {/* Shop */}
            <Route exact path="/shop-full" component={Shopfull} />
            <Route exact path="/shop-left" component={Shopleft} />
            <Route exact path="/shop-right" component={Shopright} />
            <Route
              exact
              path="/product-details/:id"
              component={Productdetails}
            />
            <Route exact path="/vendor-map" component={Vendormap} />
            {/* Additional */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/geo-locator" component={Geolocator} />
            <Route exact path="/comparison" component={Comparison} />
            {/* Blog */}
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog-v2" component={Blogtwo} />
            <Route exact path="/blog-details/:id" component={Blogdetails} />
            {/* Extra */}
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/error-page" component={Error} />
            <Route exact component={Error} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
