const initialState = {
  cartList: [
    {
      id: 5,
      img: "assets/img/details/LOGO.png",
      productname: "Sicapay Voucher",
      price: 2000,
      qty: 1,
    },
  ],
  totalValue: 2000,
};

const getTotal = (data) => {
  let total = 0;
  const totalData = data.map((item) => {
    //console.log("acc", accumulator, item);
    total += item.price * item.qty;
    return item;
  });

  return total.toFixed(2);
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CART":
      console.log(action.payload, "PAYLOAD");

      console.log(getTotal([...state.cartList, action.payload]));

      return {
        ...state,
        cartList: [...state.cartList, action.payload],
        totalValue: getTotal([...state.cartList, action.payload]),
      };

    case "UPDATE_CART":
      console.log(action.payload, "PAYLOAD");
      const newData = state.cartList.map((ele) => {
        if (ele.id === action.payload.id) {
          return action.payload;
        } else {
          return ele;
        }
      });
      console.log(getTotal([...newData]));
      return {
        ...state,
        cartList: [...newData],
        totalValue: getTotal([...newData]),
      };
    case "REMOVE_CART":
      const filterData = state.cartList.filter(
        (ele) => ele.id != action.payload
      );

      return {
        ...state,
        cartList: [...filterData],
        totalValue: getTotal([...filterData]),
      };

    default:
      return state;
  }
};

export default cartReducer;
